.Login {
  display: flex;
  display: -ms-flexbox;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 90vh;
  .login_form {
    background-color: var(--bg-white);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    height: 100%;
    .login_form__banner__image {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 60%;
        object-fit: contain;
      }
    }
    .login_form_input {
      height: 30%;
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: column;
      .login_form {
        width: 100%;
        div {
          width: 70%;
        }
      }
    }
  }
  .login_Welcome_Image,
  .login_form {
    width: 50%;
    height: 100%;
  }
  .login_Welcome_Image {
    .login_Welcome_Image__bg {
      width: 100%;
      height: 100%;
      background-size: cover;
    }
    .login_Welcome_Image__Text {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
      width: 100%;
      height: 100%;
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 800px) {
  .Login {
    min-height: 120vh;
    flex-direction: column;
    .login_Welcome_Image {
      width: 100%;
      height: 30%;
      .login_Welcome_Image__bg {
        object-fit: fill;
      }
    }
    .login_form {
      height: 100%;
      width: 100%;
      .login_form_input {
        height: 40%;
      }
    }
  }
}
