.Image_404 {
  max-height: 60%;
  margin-bottom: 2%;
  max-width: 100%;
}
.brand_input:focus:focus-within {
  border: 1px solid #e5e5e5 !important;
  border-radius: 5px !important;
  outline: 0 !important;
  width: 100% !important;
  padding: 15px 20px;
  font-size: 16px;
  color: #202124;
}
.brand_image{
  height:auto;
  width:32px;
}
