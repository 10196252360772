.Welcome {
  display: flex;
  justify-content: center;
  align-items: center;
  &_Container {
    width: 90%;
    height: 83vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .Welcome_Image {
      flex: 1;
      img {
        width: 100%;
        align-self: center;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
    }
    .Welcome_Social {
      & > a {
        padding: 2px 0px;
      }
      flex: 0.1;
      img {
        width: 30px;
        height: 30px;
      }
    }
    .Welcome_Intro {
      flex: 1;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;
      .Intro_Container {
        width: 90%;
        min-height: 32vh;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
        
        .Contact_Button {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: fit-content;
          height: 40px;
          padding: 2%;
          gap: 12px;
          background-color: var(--brand-secondary-color);
          color: var(--brand-regular-text-color);
          border-radius: 6px;
        }
      }
      .Intro_Container > * {
        padding-bottom: 3%;
      }
    }
  }
}
.Welcome_Intro_Heading_Title_Text_Location_Information{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
  font-family: "Google_Sans_b";
  text-align: center;
  text-transform: capitalize;
  font-size: max(20px, 1vw);
  color: var(--brand-secondary-color);
  img{
    width: 1.5rem;
    height: 1.2rem;
  }
}
.Creative {
  background-image: url("../../assests/image/desktop/image-header.jpg");
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .Creative_Title {
    height: 30vh;
  }
  .Creative_Arrow {
    span {
      font-size: 4rem;
    }
  }
}
.container {
  width: 100vw;
  min-height: 90vh;
  max-height: min-content;
  padding-bottom: 3%;
}
.Brand {
  width: 100vw;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container_brand {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  padding: 3%;
}
.Brand_Text {
  width: 50vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-white);
}
.Brand_Logo {
  width: 50vw;
  height: 100%;
  background-image: url("../../assests/image/desktop/image-transform.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.standout {
  height: 70vh;
  width: 100vw;
  display: flex;
  .standout_image {
    background-image: url("../../assests/image/desktop/image-stand-out.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 50%;
  }
  .standout_text {
    background-color: var(--bg-white) !important;
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.Benefits {
  width: 100vw;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .Graphics {
    width: 50%;
    height: 100%;
    background-image: url("../../assests/image/desktop/image-graphic-design.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .Photgraphy {
    width: 50%;
    height: 100%;
    background-image: url("../../assests/image/desktop/image-photography.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
}
.height40 {
  height: 40% !important;
  text-align: center;
}
.Images_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 45vh;
  .Block-1,
  .Block-2 {
    width: 50%;
    height: 100%;
    display: flex;
    .Bottle_Image,
    .Orange_Image,
    .Icecream_Image,
    .Sugar_Image {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    .Bottle_Image {
      background-image: url("../../assests/image/desktop/image-gallery-milkbottles.jpg");
    }
    .Orange_Image {
      background-image: url("../../assests/image/desktop/image-gallery-orange.jpg");
    }
    .Icecream_Image {
      background-image: url("../../assests/image/desktop/image-gallery-cone.jpg");
    }
    .Sugar_Image {
      background-image: url("../../assests/image/desktop/image-gallery-sugarcubes.jpg");
    }
  }
  .Block-1 > *,
  .Block-2 > * {
    width: 50%;
    height: 100%;
    display: flex;
  }
}
.Clients {
  background-color: var(--bg-white);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  .Client_Wraper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40vh;

    .Client_container {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: column;
      height: 100%;
      width: 33%;
      padding: 2%;
      margin: 2%;
      border: 0.6px solid var(--brand-shadow-color);
      box-shadow: 0px 0px 15px var(--brand-shadow-color);
      border-radius: 1%;
      .Client_Comment,
      .Client_Information {
        text-align: center;
      }
      .Client_Image {
        width: 70px;
        height: 70px;
        border-radius: 50%;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .Welcome_Container {
    flex-direction: column-reverse;
  }
  .Welcome_Intro {
    width: 100%;
  }
  .Intro_Container {
    width: 100% !important;
    height: 100% !important;
  }
  .Creative {
    min-height: 100vh;
    width: 100vw;
    background-image: url("../../assests/image/mobile/image-header.jpg");
  }
  .Brand {
    flex-direction: column;
    height: 100vh;
    .Brand_Logo {
      background-image: url("../../assests/image/mobile/image-transform.jpg");
    }
  }
  .Brand > * {
    width: 100vw;
    height: 50vh;
  }
  .standout {
    width: 100vw;
    height: 100vh;
    flex-direction: column-reverse;
    .standout_image {
      background-image: url("../../assests/image/mobile/image-stand-out.jpg");
      width: 100%;
    }
    .standout_text {
      width: 100%;
    }
  }
  .Benefits {
    width: 100vw;
    height: 140vh;
    flex-direction: column;
    .Graphics {
      width: 100%;
      height: 50%;
      background-image: url("../../assests/image/mobile/image-graphic-design.jpg");
    }
    .Photgraphy {
      width: 100%;
      height: 50%;
      background-image: url("../../assests/image/mobile/image-photography.jpg");
    }
  }

  .Images_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 70vh;
    flex-direction: column;
    .Block-1,
    .Block-2 {
      width: 100%;
      height: 100%;
      display: flex;
      .Bottle_Image,
      .Orange_Image,
      .Icecream_Image,
      .Sugar_Image {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
      .Bottle_Image {
        background-image: url("../../assests/image/mobile/image-gallery-milkbottles.jpg");
      }
      .Orange_Image {
        background-image: url("../../assests/image/mobile/image-gallery-orange.jpg");
      }
      .Icecream_Image {
        background-image: url("../../assests/image/mobile/image-gallery-cone.jpg");
      }
      .Sugar_Image {
        background-image: url("../../assests/image/mobile/image-gallery-sugar-cubes.jpg");
      }
    }
    .Block-1 > *,
    .Block-2 > * {
      width: 100%;
      height: 100%;
      display: flex;
    }
  }
  .Clients {
    height: 150vh !important;
    .Client_Wraper {
      flex-direction: column;
      height: 140vh !important;
      width: 90% !important;

      .Client_container {
        width: 100% !important;
      }
    }
  }
  .container {
    height: 70vh;
  }
}
