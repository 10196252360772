// importing scss
:root {



  --brand-primary-color: #0c2074;

  --brand-regular-text-color: #ffffff;
  --brand-secondary-color: #A0E7E5;
  --brand-danger-color: #db4437;
  --brand-warn-color: #f4b400;
  --brand-text-dark-color: #202124;
  --brand-text-dark-secondary-color: #5f6368;
  --brand-text-light-secondary-color: #c4c4c4;
  --white: 0 0% 90%;
  --bg-white: #fff;
  --brand-shadow-color: rgba(0, 0, 0, 0.1);
  --brand-white-shadow-color: rgba(255, 255, 255, 0.6);
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
}
p,
h1,
h2,
h3,
h4,
h4,
title {
  margin: 0%;
  padding: 0%;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--brand-primary-color);
  min-height: 100vh;
  color: var(--brand-regular-text-color);
  font-family: "Google-Sans_r", "Helvetica Neue", "Helvetica";
  overflow-x: hidden;
  line-height: 1.4;
}
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
footer {
  margin-top: auto;
}
@font-face {
  font-family: "futura_m";
  src: url(./assests/fonts/Futura-Med.woff) format("woff");
}
@font-face {
  font-family: "Google-Sans_r";
  src: url(./assests/fonts/Google-Sans.woff2) format("woff2");
}
@font-face {
  font-family: "Google-Sans_b";
  src: url(./assests/fonts/Google-Sans-BOLD.woff2) format("woff2");
}
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(./assests/fonts/Google-Icons.woff2) format("woff2");
}
@font-face {
  font-family: "Brand_B";
  src: url(./assests/fonts/Dongle-Bold.ttf) format("ttf");
}
.Brand_B {
  font-weight: 700;
}
*,
*::after,
*::before {
  box-sizing: border-box;
}
body::-webkit-scrollbar {
  width: 3px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: var(--brand-primary-color); /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: var(
    --brand-secondary-color
  ); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}
@import "./styles/index.scss";
