.projects_container{
    display: flex;
    flex-direction: column;
    text-align: center;
    
    justify-content: space-around;
    color: var(--brand-secondary-color);
    &_title{
        font-size: 1rem;
        font-family: "Google-Sans_r";
        display: flex;
        flex-direction: column;
        justify-items: center;
       
        
      &_text{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        text-align: center;
        align-items: center;
        margin: 1.5rem;
        //text-transform: capitalize;
        color: var(--brand-regular-text-color);
      }
    }
}
.Why_Us__Wrap{
    width: 28%;
    //height: min(50vh,30rem);
    display: flex;
    justify-content: center;
    margin: 2%;
    border: 0.6px solid var(--brand-shadow-color);
    background: var(--brand-secondary-color);
    box-shadow: 0 0 2px var(--bg-white);
    cursor: pointer;
    position: relative;
    transition: display 3s ease;
    border-radius: 15px;
}
