.Title_Text {
  font-family: "Google_Sans_b";
  font-size: unquote("max(32px, 2vw)");
  color: var(--brand-secondary-color);
}
.SubHeading_Text {
  font-family: "Google_Sans_r";
  font-size: unquote("max(24px, 1.4vw)");
  color: var(--brand-warn-color);
}
.Title_Text span {
  color: var(--brand-secondary-color);
}

.Text {
  font-family: "Google-Sans_r";
  font-size: unquote("max(14px, 1vw)");
}
.Sub_Text {
  font-family: "Google-Sans_r";
}
a {
  font-family: "Google-Sans_r";
  color: var(--brand-secondary-color);
  text-decoration: none;
  text-transform: uppercase;
}
.intro_link span {
  color: var(--brand-secondary-color);
  text-transform: uppercase;
}
.white {
  color: var(--brand-regular-text-color);
}
.black {
  color: var(--brand-text-dark-color);
}
.danger_text {
  color: var(--brand-danger-color);
}
.light_black {
  color: var(--brand-text-dark-secondary-color);
}
.white_text {
  color: var(--brand-regular-text-color);
}
.center {
  text-align: center;
  align-self: center;
}
.Contact__Benefits {
  width: 100%;
  & span {
    margin: 1%;
    padding: 1%;
  }
}
input,
textarea,
select {
  font-family: inherit;
}
.error_message {
  padding: 2%;
}
