@media screen and (max-width: 900px) {
  .About_Section {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    padding: 5% 0%;

    .About_Section__do {
      text-align: center;
      font-size: 2rem;
      color: var(--bg-white);
    }
    &__grid {
      display: grid;
      display: -ms-grid;
      display: -moz-grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 2rem;

      width: 90%;
      .Fancy-grid {
        padding: 1rem;
        display: grid;
        background-color: var(--brand-secondary-color);
        box-shadow: 0px 0px 4px var(--brand-text-light-secondary-color);
        &__title {
          margin-top: auto;
          font-size: 2rem;
          font-weight: 600;
          line-height: 1;
          width: min-content;
          margin-top: auto;
          color: var(--bg-white);
        }
        &__image,
        &__arrow {
          display: none;
          transform: translate(0%, 0%);
        }
        &__number {
          text-align: right;
          color: var(--brand-primary-color);
          color: var(--bg-white);
        }
      }
    }
  }
}

.Why_Us {
  display: flex;
  text-align: center;
  justify-content: space-evenly;
  min-height: fit-content;
  align-items: center;
  flex-wrap: wrap;
  margin: auto;
  width: 100vw;
  padding: 2% 0%;
  &__Intro {
    padding: 2rem 0;
    border-top: 1px solid var(--brand-secondary-color);
    width: 100%;
  }
  .Why_Us__Wrap {
    width: 28%;
    height: unquote($string: "min(50vh, 30rem)");
    display: flex;
    justify-content: center;
    margin: 2%;
    border: 0.6px solid var(--brand-shadow-color);
    background: var(--brand-secondary-color);
    box-shadow: 0px 0px 2px var(--bg-white);
    cursor: pointer;
    position: relative;
    transition: display 3s ease;
    border-radius: 15px;
    .Why_Us_Contaiiner {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 100%;
      flex-direction: column;

      .Why_Us_Text {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        text-align: center;
        width: 100%;
        padding: 2%;
        flex-direction: column;
        .Sub_Text {
          display: none;
        }
      }
      .Why_Us_Image {
        width: 100%;
        height: 30vh;
        display: flex;
        justify-content: center;
        align-items: center;
        object-fit: contain;
        img {
          width: 95%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    &:hover,
    &:focus {
      .Sub_Text {
        display: flex !important;
        z-index: 2;
        position: absolute;
        height: 100%;
        background-color: var(--brand-primary-color);
        inset: 0;
        justify-content: center;
        align-items: center;
        padding: 2% 3%;
        border-radius: 15px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .Why_Us {
    .Why_Us__Wrap {
      width: 100%;
      height: 100%;
      margin: 3% 1%;
      max-height: 25rem;
      padding: 10% 0%;
      .Why_Us_Contaiiner {
        flex-direction: column;
        justify-content: center;
        height: 100%;
        max-height: 20rem;
        .Why_Us_Text {
          width: 100%;
          height: 60%;
          padding: 2%;
          flex-direction: column;
          order: 2;
          justify-content: space-evenly;
        }
        .Why_Us_Image {
          width: 100%;

          order: 1;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 900px) {
  .About_Section {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    width: 100vw;
    height: 40vw;
    background-color: var(--bg-white);
    &__grid {
      width: unquote($string: " min(100% - 2rem,70rem)");
      margin: 4.7rem auto;
      display: grid;
      display: -ms-grid;
      display: -moz-grid;
      grid-template-columns: repeat(4, 1fr);
      grid-row: 2;
      height: 100%;

      gap: 1rem;
      .Fancy-grid {
        min-width:200px;
        aspect-ratio: 1/1;
        padding: 1rem;
        border: 1px solid var(--brand-text-light-secondary-color);
        border-bottom: 3px solid var(--brand-primary-color);
        position: relative;
        display: grid;
        grid-row: 2;
        cursor: pointer;

        &__large {
          aspect-ratio: 1/1;
        }
        &__up {
          grid-row: 1 / span 2;
        }
        &__down {
          grid-row: 2 / span 2;
        }
        &__title {
          margin-top: auto;
          font-size: 2rem;
          font-weight: 600;
          line-height: 1;
          width: min-content;
          margin-top: auto;
        }
        &__arrow {
          display: none;
        }
        &__number {
          text-align: right;
        }
        &__image {
          position: absolute;
          bottom: 0%;
          z-index: -3;
        }
        &__arrow {
          display: none;
          transform: translate(0%, 0%);
        }
        &::before,
        &::after {
          content: "";
          position: absolute;
          inset: 0;
        }
        &::after {
          z-index: -1;
          transform: translate(0, 0);
          background: var(--brand-secondary-color);
        }
        &::before {
          opacity: 0;
          z-index: -3;
          transform: translate(0, 0);
          background: var(--brand-text-dark-color);
          filter: blur(2rem);
          transition: transform 350ms ease, opacity 350ms ease;
        }
      }

      .Fancy-grid:focus,
      .Fancy-grid:hover {
        transform: scale(1.35);
        z-index: 10;
        transition: transform 350ms ease-in, background-color 350ms ease,
          z-index 350ms ease;
        animation: shake 500ms ease;
        animation-delay: 350ms;
        background: var(--brand-secondary-color);
        border: none;
        .Fancy-grid__title,
        .Fancy-grid__number {
          color: var(--bg-white) !important;
        }
        .Fancy-grid__arrow {
          display: block;
          position: absolute;
          bottom: 10px;
          right: 5%;
          color: var(--bg-white);
        }
        .Fancy-grid__image {
          transform: translate(0%, 0%);
          transition: transform 150ms ease, rotate 150ms ease;
        }
        @for $image from 1 through 5 {
          .Fancy-grid__image:nth-of-type(#{$image}) {
            transition-delay: 700ms + ($image * 100);
          }
        }
        .Fancy-grid__image:nth-of-type(1) {
          transform: translate(250%, -100%) rotate(10deg);
        }
        .Fancy-grid__image:nth-of-type(2) {
          transform: translate(270%, 0%) rotate(10deg);
        }
        .Fancy-grid__image:nth-of-type(3) {
          transform: translate(0%, 100%) rotate(10deg);
        }
        .Fancy-grid__image:nth-of-type(4) {
          transform: translate(100%, 100%) rotate(10deg);
        }
        .Fancy-grid__image:nth-of-type(5) {
          transform: translate(220%, 100%) rotate(10deg);
        }
        &::before {
          opacity: 0.2;
          transform: translate(-50%, 50%);
        }
        .Fancy-grid__image__overide2:nth-of-type(1) {
          transform: translate(270%, -100%);
        }
        .Fancy-grid__image__override3:nth-of-type(1) {
          transform: translate(-120%, -100%) rotate(10deg);
        }
        .Fancy-grid__image__override3:nth-of-type(2) {
          transform: translate(-120%, 0%) rotate(10deg);
        }
        .Fancy-grid__image__override3:nth-of-type(3) {
          transform: translate(-25%, 90%) rotate(10deg);
        }
        .Fancy-grid__image {
          position: absolute;
          z-index: -1;
          width: 40%;
          bottom: 0;
        }
      }
    }
  }
  @keyframes shake {
    0% {
      transform: scaleX(1.35) rotate(0deg);
    }
    25% {
      transform: scale(1.35) rotate(5deg);
    }
    50% {
      transform: scale(1.35) rotate(-5deg);
    }
    75% {
      transform: scale(1.35) rotate(5deg);
    }
  }
}
@media screen and (min-width: 900px) and (max-width: 1250px) {
  .About_Section {
    .Fancy-grid:focus:nth-of-type(1),
    .Fancy-grid:hover:nth-of-type(1) {
      transform: scale(1.15);
    }
    .Fancy-grid:focus:nth-of-type(4),
    .Fancy-grid:hover:nth-of-type(4) {
      transform: scale(1.15);
    }
  }
}
.container_restrict {
  width: unquote($string: "min(98vw, 95rem)");
}
