.Reset_Section {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: var(--bg-white);
  flex-direction: column;
  .Reset_Section__form {
    width: 40%;
    max-width: 760px;
    height: 50%;
    box-shadow: 0 15px 35px 0 rgba(60, 66, 87, 0.08),
      0 5px 15px 0 rgba(0, 0, 0, 0.12);
    padding: unquote($string: "min(5%, 50px)");
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    .Reset_Form {
      height: 50%;
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
    }
  }
  .Reset_Section__Gap {
    width: 50%;
    height: 20%;
  }
}

@media screen and (max-width: 900px) {
  .Reset_Section {
    .Reset_Section__form {
      width: 100%;
      box-shadow: none;
    }
    .Reset_Section__Gap {
      width: 0%;
      height: 0%;
    }
  }
}
